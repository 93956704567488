import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Every App is a Communications App",
  "date": "2014-12-15T00:00:00.000Z",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Messaging as a primary way of interaction is strange trend for us in North America, because it didn’t become a thing here until very recently. What’s great about messaging is that everyone just gets it. Because of that, it’s a pattern that can make a lot of sense for many types of apps.`}</p>
    <p>{`Ron Palmeri, CEO of Layer `}<a parentName="p" {...{
        "href": "http://blog.layer.com/every-app-is-a-communications-app/"
      }}>{`recently shared a few ideas`}</a>{` on how some apps would be way better with the integration of messaging:`}</p>
    <blockquote>
      <p parentName="blockquote">{`This app is essentially a marketplace for babysitters and parents in need of a babysitter. The app pre-qualifies babysitters through background checks and then presents parents with a list of available babysitters, based on the date, time and experience criteria the parent sets.`}</p>
      <p parentName="blockquote">{`Transformation opportunities:`}</p>
      <ul parentName="blockquote">
        <li parentName="ul">{`Messaging to connect parents to each other to collaborate and discuss the selection and ultimate hiring of a babysitter.`}</li>
        <li parentName="ul">{`During-job photo or video messaging from babysitter/kids to parents to keep parents updated on the kids’ well being.`}</li>
        <li parentName="ul">{`Sharing of checklists and other content types so parents and babysitter can stay in sync during the job.`}</li>
      </ul>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      